// ** Redux Imports
import { createSlice } from '@reduxjs/toolkit'
import { Cookies } from 'react-cookie'
// ** UseJWT import to get config
import useJwt from '@src/auth/jwt/useJwt'

const config = useJwt.jwtConfig

// const initialUser = () => {
//   const item = window.localStorage.getItem('userData')
//   //** Parse stored json or if none return initialValue
//   return item ? JSON.parse(item) : {}
// }
const cookies = new Cookies()
export const authSlice = createSlice({
  name: 'statusNames',
  initialState: {
    statusData: []
  },
  reducers: {
    HandleStatusNames: (state, action) => {
      console.log("INSIDE REDEX:", action.payload)
      state.statusData = action.payload
      
    }
    
    // handleLogout: state => {
    //   console.log("LOGOUT IS BEING DNE!")
    //   state.userData = {}
    //   state[config.storageTokenKeyName] = null
    //   state[config.storageRefreshTokenKeyName] = null
    //   // ** Remove user, accessToken & refreshToken from localStorage
    //   localStorage.removeItem('userData')
    //   localStorage.removeItem(config.storageTokenKeyName)
    //   localStorage.removeItem(config.storageRefreshTokenKeyName)

    //   cookies.remove('accessToken')
    // cookies.remove('userData')
    // }
  }
})

export const { HandleStatusNames} = authSlice.actions

export default authSlice.reducer
