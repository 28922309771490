import { getRequest, postRequest, imageRequest, putRequest } from "../commons/API"

class CustomerService {

    getCustomerId(meterId) {
        //('meterId: ', meterId)
        return getRequest(`customers/meters/getCustomerId?meterId=${meterId}`)
    }
    getOneMeterBalanceStatus(meterId) {
        //('meterId: ', meterId)
        return getRequest(`customers/meters/oneMeterBalanceStatus?meterId=${meterId}`)
    }
    billSummary(meterId, currentPage, rowsPerPage) {
        //('meterId: ', meterId)
        return getRequest(`customers/meters/billSummary?meterId=${meterId}&start=${currentPage}&length=${rowsPerPage}`)
    }
    getMeterStatement(meterId, currentPage, rowsPerPage) {
        //('meterId: ', meterId)
        return getRequest(`customers/meters/statement?meterId=${meterId}&start=${currentPage}&length=${rowsPerPage}`)
    }
    getStatusList(meterId) {
        //('meterId: ', meterId)
        return getRequest(`customers/meters/statusList?meterId=${meterId}`)
    }
    getImage(customerId) {
        //('meterId: ', meterId)
        return imageRequest(`customers/customerphoto/${customerId}`)
    }
    addMeterStatus(meterStatus) {
        //('Customer: ', meterReset)
        return postRequest('customers/meters/status', meterStatus)
    }
    editMaster(meter) {
        console.log('Customer Meter: ', meter)
        return postRequest('customers/meters/master/edit', meter)
    }
    addTransfer(data) {
        console.log('Customer Meter: ', data)
        return postRequest('transfer/save', data)
    }
    oneMaster(meterId) {
        //('meterId: ', meterId)
        return getRequest(`customers/meters/master?meterId=${meterId}`)
    }
    oneBalance(meterId) {
        //('meterId: ', meterId)
        return getRequest(`transfer/balance?meterId=${meterId}`)
    }
    editCustomer(customer) {
        //('Customer: ', meterReset)
        return postRequest('customers/edit', customer)
    }
    editImage(data) {
        //('Customer: ', meterReset)
        return postRequest('customers/editImage', data)
    }

    createTenant(tenant) {
        console.log('METER RESET 200: ', tenant)
            return postRequest('customers/tenants/add', tenant)
        }
    resetMeter(meterReset) {
    console.log('METER RESET 200: ', meterReset)
        return postRequest('customers/meters/reset', meterReset)
    }
    editMeter(meter) {
        //('Customer: ', meter)
        return postRequest('customers/meters/edit', meter)
    }
    oneMeterForEdit(meterId) {
        //('meterId: ', meterId)
        return getRequest(`customers/meters/edit?meterId=${meterId}`)
    }
    addCustomer(customer) {
        //('Customer: ', customer)
        return postRequest('customers', customer)
    }
    addCustomerMeterWithImage(customerInfo) {
        console.log('customerInfo: ', customerInfo)
        return postRequest('customers/addCustomer', customerInfo)
    }
    addCustomerWithImage(customer) {
        //('Customer: ', customer)
        return postRequest('customers/addWithImage', customer)
    }
    oneCustomer(customerId) {
        //('Customer: ', customerId)
        return getRequest(`customers/${customerId}`)
    }

    customerMeters(customerId) {
        //('CustomerID: ', customerId)
        return getRequest(`customers/customermeters/${customerId}`)
    }

    addMeter(meter) {
        //('Customer: ', meter)
        return postRequest('customers/meter', meter)
    }
    getOneMeter(meterId) {
        //('CustomerID: ', meterId)
        return getRequest(`customers/meters/${meterId}`)
    }
    getMeterTenantInfo(meterId) {
        //('CustomerID: ', meterId)
        return getRequest(`customers/tenants/info?meterId=${meterId}`)
    }

    getMeterSearchList(start, length, zone, name, meterId, serialNumber, phone) {
        return getRequest(`customers/meters?start=${start}&length=${length}&zone=${zone}&name=${name}&meterId=${meterId}&serialNumber=${serialNumber}&phone=${phone}`)
    }
    getMeterSearchListCount(start, length, zone, name, meterId, serialNumber, phone) {
        return getRequest(`customers/meters/counts?start=${start}&length=${length}&zone=${zone}&name=${name}&meterId=${meterId}&serialNumber=${serialNumber}&phone=${phone}`)
    }


    getTenantsSearchList(start, length) {

        return getRequest(`customers/tenants/list`)
    }

    getTenantsSearchListCounts() {
        return getRequest(`customers/tenants/counts`)
    }

    addTenantMeterRelation(tenant) {
        //('Customer: ', meter)
        return postRequest('customers/meter/tenants/add', tenant)
    }
    updateTenantMeterRelation(tenant) {
        //('Customer: ', meter)
        return postRequest('customers/meter/tenants/meter/update', tenant)
    }

    getSingleDetail(trno, table) {
        return getRequest(`public/statement/details?trno=${trno}&tableName=${table}`)
    }
}

export default new CustomerService()