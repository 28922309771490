import { createSlice } from '@reduxjs/toolkit'
import customerservice from '../services/customerservice'


export const dataSlice = createSlice({
  name: 'data',
  initialState: {
    loading: false,
    error: null,
    data: null
  },
  reducers: {
    fetchDataStart: (state) => {
      state.loading = true
      state.error = null
      state.data = null
    },
    fetchDataSuccess: (state, action) => {
      state.loading = false
      state.error = null
      state.data = action.payload
    },
    fetchDataError: (state, action) => {
      state.loading = false
      state.error = action.payload
      state.data = null
    }
  }
})

export const { fetchDataStart, fetchDataSuccess, fetchDataError } = dataSlice.actions

export const fetchAsyncData = (meterId) => async (dispatch) => {
  dispatch(fetchDataStart())
  try {
    const response = await customerservice.getOneMeterBalanceStatus(meterId)
    dispatch(fetchDataSuccess(response.data))
  } catch (error) {
    dispatch(fetchDataError(error.message))
  }
}

export default dataSlice.reducer
