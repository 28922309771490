import axios from 'axios'
import { Cookies } from 'react-cookie'
import { Navigate  } from 'react-router-dom'

//const PUBLIC_URL = 'http://localhost:9000'
const PUBLIC_URL = 'http://104.199.16.203:8090/SPCVESION2-0.0.1-SNAPSHOT'
//const PUBLIC_URL = 'http://104.199.16.203:8090/SPCVESION2-0.0.1-SNAPSHOT'
const api = axios.create({
  baseURL: `${PUBLIC_URL}`
})
const apiLogin = axios.create({
  
    baseURL: `${PUBLIC_URL}`
  })

  const apiImage = axios.create({
   // baseURL: `${PUBLIC_URL}`
  })

  const apiReport = axios.create({
    baseURL: `${PUBLIC_URL}`
  })
api.defaults.headers = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    "Access-Control-Allow-Headers": "*",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "*"
  }
  apiLogin.defaults.headers = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    "Access-Control-Allow-Headers": "*",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "*"
  }

  export function reportRequest(URL) {
  
    //console.log("URl: ", `${baseURL}/${URL}`, " Payload: ")
      return api.get(`/${URL}`, { responseType: "blob" }).then(response => response)
    }
    export function reportPostRequest(URL, payload) {
      console.log('Payload: ', payload)
      return api.post(`/${URL}`, payload, { responseType: "blob" }).then(response => response)
    }
  
    export function imageRequest(URL) {
    
      //console.log("URl: ", `${baseURL}/${URL}`, " Payload: ")
        return api.get(`/${URL}`, { responseType: "arraybuffer" }).then(response => response)
      }
  export function loginRequest(URL, payload) {
    //console.log("apiLogin: ", `${PUBLIC_URL}/${URL}`)
    //console.log("URl: ", `${baseURL}/${URL}`, " Payload: ", payload)
      return apiLogin.post(`/${URL}`, payload).then(response => response)
    }

    export function getRequest(URL) {
      console.log("Get URL: ", URL)
      return api.get(`/${URL}`).then(response => response)
    }

    export function postRequest(URL, payload) {
      console.log('Payload: ', payload)
      return api.post(`/${URL}`, payload).then(response => response)
    }
    export function putRequest(URL, payload) {
      console.log('Payload: ', payload)
      return api.put(`/${URL}`, payload).then(response => response)
    }

    export function patchRequest(URL, payload) {
      return api.patch(`/${URL}`, payload).then(response => response)
    }
    
    export function deleteRequest(URL) {
      return api.delete(`/${URL}`).then(response => response)
    }


    const cookies = new Cookies()
api.interceptors.request.use((config) => {
 // const token = localStorage.getItem('token')
  const token = localStorage.getItem('accessToken')
  //const token = JSON.stringify(cookies.get('accessToken'))
  console.log("TOKEN FROM LOCALSTORAGE: ", cookies.get('accessToken'))
  console.log("TOKEN INTERCEPTOR: ", cookies.get('accessToken'))
  if (token) {
    //config.headers.Authorization = `Bearer ${token}`
   // config.headers['authorization'] = `Bearer  ${token.slice(1, -1)}`
    config.headers['authorization'] = `Bearer  ${token.slice(1, -1)}`
  }
  config.headers['Content-Type'] = 'application/json'
  return config
})

function navigateToPage(history, path) {
  history.push(path)
}


// api.interceptors.response.use(
//   (response) => response,
//   async (error) => {
//     const originalRequest = error.config

//     if (
//         [401, 403].includes(error.response.status)
//       //error.response.status === 401
    
//        &&
//       !originalRequest._retry
//     ) {
//       originalRequest._retry = true
      
//        // Handle refresh token error
//        console.log("TOKEN HAS EXPIRED....")
//        localStorage.removeItem('userData')
//      localStorage.removeItem('accessToken')
//      localStorage.removeItem('refreshToken')
//       //navigate('/login')
//       return <Navigate to="/login" />
//       try {
//         const response = await axios.post('/refresh-token')

//         localStorage.setItem('token', response.data.token)

//         return api(originalRequest)
//       } catch (error) {
       
//       //const history = useHistory()
      
//       }
//     }

//     return Promise.reject(error)
//   }
// )

export {api}
export default api
